@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&display=swap");

:root {
  --p: #232d51;
  --s: #192550;
  --t: #ffca00;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
  list-style: none !important;
  text-decoration: none !important;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.popp {
  font-family: "Poppins", sans-serif !important;
}
.caveat {
  font-family: "Caveat", cursive;
  font-weight: 500;
}
.ffca00 {
  color: #fff;
}
.hrline {
  position: relative;
  display: flex;
  justify-content: center;
}
.hrline::before {
  content: "";
  position: absolute;
  text-align: center;
  bottom: 0;
  height: 3px;
  background-color: #fff;
  width: 100px;
}
@media screen and (min-width: 993px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(#333, #000);
  }
}
