.games-container {
  padding: 20px;
  background-color: #2c2c2e;
  color: #fff;
}

.game-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.game-card {
  margin-top: 40px;
  width: 200px;
  max-width: 250px;
  flex-shrink: 0;
  height: 200px;
  background-color: #2c2c2e;
  color: #fff;
  border-radius: 1em;
  text-align: center;
  position: relative;
  box-shadow: 0 3px 14px 0 rgba(4, 0, 0, 0.51);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 33px 8px 8px;
}

.card-img-container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: absolute;
  top: -50px;
  left: 30%;
  border-radius: 1em;
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(4, 0, 0, 0.51);
}

.card-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1em;
}

.card-content {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.game-title {
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
  margin: 5px 0;
  margin-bottom: 15px;

}

.game-discount {
  font-size: 1.3rem;
  color: #abadaf;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.top-up-btn {
  padding: 3px;
  color: #ff962d;
  border: 1px solid #ff962d;
  border-radius: 1em;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.top-up-btn:hover {
  background-color: #ff962d;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .game-card {
    margin-top: 10px;
    width: 48%;
  }
}

@media screen and (max-width: 600px) {
  .game-card {
    width: 46%;
    margin-bottom: 30px;
    height: auto;
  }

  .card-img-container {
    margin-top: 0px;
    width: 65px;
    height: 65px;
    top: -30px;
  }
}
