.admin-product-page-container {
    padding: 20px;
  }
  
  .page-title h3 {
    margin: 0;
    color: black;
  }
  
  .tools {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .table-container {
    display: none; /* Hide table view by default */
  }
  
  .product-card-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .product-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .product-image {
    width: 100px; /* Set a fixed width for smaller images */
    height: auto;
    border-radius: 8px;
    align-self: center; /* Center the image within the card */
  }
  .table-image {
    width: 100px; /* Set a fixed width for smaller images */
    height: auto;
    border-radius: 8px;
    align-self: center; /* Center the image within the card */
  }

  .product-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: black;
  }
  
  .product-info strong {
    color: #333;
    font-weight: bold;
  }
  
  .action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  @media (min-width: 768px) {
    .table-container {
      display: block; /* Show table view on larger screens */
    }
  
    .product-card-container {
      display: none; /* Hide card view on larger screens */
    }
  }