.hero-container {
  padding: 100px 200px;
  background-color: var(--p);
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}
.hero-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: #ffca00;
    font-weight: bold;
  }
}
.explore-btn {
  background-color: #ffca00;
  padding: 10px 20px;
  border-radius: 100px;
  color: #000 !important;
  width: 150px;
  text-align: center;
  margin-top: 20px;
  outline: transparent;
  border: transparent;
  &:hover {
    opacity: 0.8;
  }
}
.hero-img {
  width: 50%;
}
.image-slider {
  overflow-x: scroll;
  border: 1px solid red;
  padding: 0;
}
.slide {
  padding: 0;
}

@media screen and (max-width: 992px) {
  .hero-container {
    padding: 50px 20px;
  }
  .hero-content {
    width: 100%;
  }
  .hero-img img {
    width: 200%;
    display: block;
    margin: 0 auto;
  }
}
