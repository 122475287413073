.admin-gallery-container {
    padding: 20px;
  }
  
  .page-title h3 {
    margin: 0;
    color: black;
  }
  
  .gallery-container {
    margin-bottom: 20px;
  }
  
  .table-container {
    display: none; /* Hide table view by default */
  }
  
  .gallery-card-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .gallery-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .gallery-image {
    width: 100px; /* Set a consistent width for images */
    height: 100px; /* Set a consistent height for images */
    object-fit: cover; /* Ensure images are not distorted */
    border-radius: 4px;
  }
  .table-image {
    width: 100px; /* Set a consistent width for images */
    height: 100px; /* Set a consistent height for images */
    object-fit: cover; /* Ensure images are not distorted */
    border-radius: 4px;
  }
  
  .gallery-info {
    display: flex;
    gap: 10px;
  }
  
  .copy-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
  }
  
  @media (min-width: 768px) {
    .table-container {
      display: block; /* Show table view on larger screens */
    }
  
    .product-table img {
      width: 100px; /* Set a consistent width for table images */
      height: 100px; /* Set a consistent height for table images */
      object-fit: cover; /* Ensure images are not distorted */
      border-radius: 4px;
    }
  
    .gallery-card-container {
      display: none; /* Hide card view on larger screens */
    }
  }