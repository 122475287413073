.admin-orders-container {
  padding: 20px;
  color: black;
}

.pagination{
  color: black;
}

.order-page-title h3 {
  margin: 0;
  color: black; /* Set text color to black */
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.date-filters,
.additional-filters,
.rows-per-page {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-pagination {
  padding: 5px 10px;
  cursor: pointer;
}

.btn-pagination.active {
  font-weight: bold;
}

.table-container {
  display: none; /* Hide table by default */
}

.order-card {
  display: block;
  color: black;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.order-card div {
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: solid 1px #ddd;
}

.text-primary {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.text-primary:hover {
  color: darkblue;
}

@media (min-width: 768px) {
  .table-container {
    display: block; /* Show table on larger screens */
  }
  .order-card {
    display: none; /* Hide card view on larger screens */
  }
}

