/* confirmation.css */
.confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .confirmation-message {
    font-size: 1.5rem;
    color: #333;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .spinner {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 0.6s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  