.api-table-container {
    width: 100%;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    color: black;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .custom-table {
    background-color: #f9f9f9;
  }
  
  /* ✅ Mobile View: Labels on left, data on right */
  @media screen and (max-width: 600px) {
    .custom-table thead {
      display: none; /* Hide table headers */
    }
  
    .custom-table tbody tr {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 10px;
      border: 1px solid #ddd;
      background: white;
      margin-bottom: 10px;
    }
  
    .custom-table td {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      font-size: 14px;
      border: none;
      width: 100%;
      border-bottom: 1px solid #e2dfdf ;
    }
  
    .custom-table td::before {
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      flex: 1;
      text-align: left;
    }
  
    .custom-table td span {
      flex: 2;
      text-align: right;
    }
  }
  