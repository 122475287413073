.admin-products-container {
  background-color: #fff;
  color: #000;
  width: 100%;
  padding: 20px;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.add-product-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-dark {
  color: #333;
}

.form-fields {
  margin-bottom: 20px;
  color: #333;
}

.form-fields input,
.form-fields select,
.form-fields textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 4px; /* Improved input styling */
}

.form-fields input[type="file"] {
  padding: 5px;
}

input[type="text"]::placeholder,
textarea::placeholder {
  color: #777;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
}

button + button {
  margin-left: 10px;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
  gap: 10px; /* Added gap between elements in flex layout */
  flex-wrap: wrap; /* Allow wrapping of fields when necessary */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: left;
  border-top: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table td {
  color: #333;
}

.table td,
.table th {
  border-bottom: 1px solid #ddd;
}

.table tbody tr:hover {
  background-color: #e9e9e9;
}

.add-product-container .text-dark small {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 5px;
}

.form-fields input[type="file"],
.form-fields select {
  border: 1px solid #ccc;
  padding: 8px;
}

.add-product-container .form-fields button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.add-product-container .form-fields button:hover {
  background-color: #0056b3;
}

.add-product-container .form-fields button:focus {
  outline: none;
}

.d-flex input {
  flex: 1 1 30%; /* Allow inputs to shrink or grow but take equal space */
}

.d-flex button {
  flex: 0 0 40px; /* Fixed size for add/remove buttons */
  padding: 5px 10px;
}
