/* General Container Styling */
.admin-container {
  width: 1200px; /* Fixed width for the container */
  margin: 0 auto;
  padding: 20px;
  color: black;
  overflow: hidden; /* Prevents content from overflowing */
}

/* Page Title */
.admin-container h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: white;
}

/* Filters Section */
.filters {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  /* flex-wrap: wrap; */
}

.filters input[type="search"] {
  padding: 8px;
  font-size: 16px;
  width: 250px;
  border: 1px solid #000;
  border-radius: 4px;
}

.filters button {
  padding: 8px 16px;
  background-color: #007bff;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.filters button:hover {
  background-color: #0056b3;
}

.filters select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: 10px;
}

/* Table Styling */
.table {
  width: 100%;
  min-width: 1000px; 
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Loader */
.loader-container {
  text-align: center;
  margin-top: 40px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Pagination Controls */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button,
.pagination .dots {
  margin: 0 5px;
  padding: 8px 16px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #007bff;
  color: white;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination .dots {
  font-size: 18px;
  color: #888;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

  .admin-container {
    width: 100%;
    padding: 0px;
    margin-top: 10px;
  }
  .filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .filters input[type="search"],
  .filters select {
    width: 100%;
    margin-bottom: 10px;
  }

  .filters button {
    width: 100%;
  }

  .table {
    min-width: 900px; /* Ensure the table doesn't shrink too much */
  }
}

@media (max-width: 480px) {

  .filters input[type="search"],
  .filters select {
    font-size: 14px;
  }

  .filters button {
    padding: 6px 12px;
    font-size: 14px;
  }

  .pagination button {
    padding: 6px 12px;
    font-size: 14px;
  }
}
.payment-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-card div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.payment-card div:last-child {
  border-bottom: none;
}

.payment-card strong {
  color: #333;
  font-weight: bold;
}

@media (min-width: 768px) {
  .payment-card {
    display: none; /* Hide payment card on larger screens */
  }
}