/* General Styles */
.register-container {
  padding: 40px 20px;
}

.track-order-title {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

/* Tabs Styling */
.tabs {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.tab-button {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 25px;
  background-color: #eee;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s;
}

.tab-button.active {
  background-color: #ffca00;
  color: #000;
}

/* Track Order Form Button */
.track-btn {
  background-color: #ffca00;
  color: #000;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s;
}

.track-btn:hover {
  background-color: #000;
  color: #fff;
}

/* Order Data Display */
.track-order-key-value {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.order-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 500px;
  max-width: 600px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order-key {
  font-weight: bold;
  color: #333;
  flex: 1;
}

.order-value {
  color: #555;
  text-align: right;
  flex: 2;
}

/* Responsive Design */
@media (max-width: 768px) {
  .order-card{
    margin: 0px;
    min-width: 0px;
    margin-bottom: 10px;
  }
  .order-item {
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
  }

  .order-key {
    text-align: left;
  }

  .order-value {
    text-align: left;
  }

  .tab-button {
    font-size: 14px;
    padding: 8px 15px;
  }
}
