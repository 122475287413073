/* Main container */
.admin-view-order {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: black;
}

/* Header */
.admin-view-order-header {
  margin-bottom: 20px;
  text-align: center;
}

.admin-view-order-header h3 {
  color: #fff;
  margin: 0;
  font-size: 1.8rem;
}

.admin-view-order-header p {
  margin: 5px 0;
  color: #fff;
}

/* Content Layout */
.admin-view-order-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Order details table */
.admin-view-order-details {
  flex: 1 1 60%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.admin-view-order-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.admin-view-order-table th,
.admin-view-order-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.admin-view-order-table th {
  background-color: #f1f1f1;
}

/* Action Section */
.admin-view-order-actions {
  flex: 1 1 35%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.admin-view-order-select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.admin-view-order-btn {
  padding: 10px 15px;
  background-color: #171a17;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
}

.admin-view-order-btn:hover {
  background-color: #414641;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-view-order-content {
    flex-direction: column;
  }

  .admin-view-order-details,
  .admin-view-order-actions {
    width: 100%;
  }
}
