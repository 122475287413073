.phone-skins-container {
  background-color: #000;
  padding: 50px 100px;
}
.phone-skins-container img {
  width: 100%;
  padding: 50px;
}

@media screen and (max-width: 992px) {
  .phone-skins-container {
    padding: 50px 0;
  }
  .phone-skins-container img {
    padding: 30px;
  }
}
