/* Overlay background */
.admin-carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Ensure it is above all other content */
}

/* Admin carousel form container */
.admin-carousel-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 400px;
    max-width: 90%;
    text-align: center;
    z-index: 1000; /* Stays above the overlay background */
}

/* Success/Error message styling */
.message.success {
    color: green;
    margin-bottom: 15px;
}

.message.error {
    color: red;
    margin-bottom: 15px;
}

/* Input and textarea styling */
.admin-carousel-container input,
.admin-carousel-container textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Submit button styling */
.banner-submit button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.banner-submit button:hover {
    background-color: #0056b3;
}
