@keyframes fadeInOut {
    0%, 100% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .loading-dots span {
    display: inline-block;
    font-size: 1.5em;
    margin: 0 2px;
    animation: fadeInOut 1.5s infinite ease-in-out;
  }
  
  .loading-dots span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  
  @keyframes fadeBounce {
    0%, 100% {
      opacity: 0.3;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-8px);
    }
  }
  
  .loading-dots span {
    animation: fadeBounce 1.5s infinite ease-in-out;
  }
  
  