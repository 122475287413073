.user-order-container {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px
}
.no-order-found {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: #eee;
  padding: 20px;
}

.view-btn {
  padding: 2px 10px;
  background-color: #2c2c2e;
  color: #fff;
}

/* General Table Styling */
.orders-table-container {
  overflow-x: auto;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table thead {
  background-color: #f5f5f5;
}

.orders-table th,
.orders-table td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.orders-table th {
  font-weight: bold;
}

/* Key-Value Pair Display for Mobile Screens */
@media (max-width: 768px) {
  .orders-table thead {
    display: none;
  }
  .orders-table tr {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  .orders-table td {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    border: none;
  }

  .orders-table td::before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    text-transform: capitalize;
  }
}

/* Button Styling */
.view-btn {
  padding: 5px 12px;
  background-color: #2c2c2e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.view-btn:hover {
  background-color: #1f1f21;
}

/* Loader Container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.no-order-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #eee;
  border-radius: 8px;
}
