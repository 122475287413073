.cart-menu-container {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  scale: 0;
  display: flex;
}
.cart-menu-container.active {
  scale: 1;
}
.cart-menu {
  position: absolute;
  left: -100%;
  z-index: 99999;
  background-color: #fff;
  width: 30%;
  height: 100%;
  padding: 20px;
  transition: 0.5s all ease;
}
.cart-menu.active {
  left: 0;
}
.topper {
  display: flex;
  justify-content: space-between;
}
.topper .icon {
  cursor: pointer;
}
.cart-items {
  min-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cart-items .icon {
  font-size: 80px;
}
.cart-items span {
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 10px;
}
.cart-items a {
  color: #333;
  text-decoration: underline !important;
}
.view-cart {
  color: #333;
  text-align: center;
  text-decoration: underline !important;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
}
@media screen and (max-width: 992px) {
  .cart-menu {
    width: 100%;
  }
}
