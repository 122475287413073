.dashboard-container {
  padding: 50px 100px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* background-color: var(--s); */
  background-color: #2c2c2e;
}
.dashboard-menu {
  width: 30%;
  padding: 20px;
  background-color: #fff;
}
.dashboard-menu ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-menu ul li {
  padding: 5px;
}
.dashboard-menu ul li.active {
  background-color: #fff;
  border-radius: 5px;
  border: solid black;
}
.dashboard-menu ul li a {
  color: #333;
}

/* CONTENT  */
.dashboard-content {
  width: 70%;
  padding: 0 20px;
}
.user-dashboard {
  display: flex;
  gap: 20px;
}
/* =============== MEDIA QUERY ===============  */
@media screen and (max-width: 992px) {
  .dashboard-container {
    padding: 0;
  }
  .dashboard-menu {
    width: 100%;
  }
  .dashboard-content {
    width: 100%;
    margin-top: 30px;
    overflow-x: scroll;
  }
  .user-dashboard {
    flex-direction: column;
  }
}
