.sidemenu-container {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100vh;
  transition: all 0.3s ease;
  z-index: 99999;
}
.sidemenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 200px;
  position: relative;
  /* background-color: var(--p); */
  background-color: #000;
  padding: 0 40px;
}
.sidemenu .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 35px;
  color: #fff;
}
.sidemenu ul li {
  margin: 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
}
.sidemenu ul li a {
  color: #fff;
  font-size: 20px;
}
.sidemenu .logout {
  color: #ffffff;
  cursor: pointer;
}
.sidemenu .logout:hover {
  color: #fff;
}

.sidemenu .logout .icon {
  font-size: 25px;
  margin-left: 5px;
}
.sidemenu-img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  object-fit: cover;
  border: 3px solid #26bd68;
  display: block;
  margin: 0 auto;
}
.active {
  left: 0;
}
.sidemenu-action-btn a {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  font-size: large;
  text-decoration: none !important;
}
/* @media screen and (max-width: 376px) {
  .sidemenu-container {
    width: 70%;
  }
  .sidemenu-action-btn .request-btn {
    font-size: 12px;
  }
} */

.sidemenu-logo{
  
}
