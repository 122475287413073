.footer-container {
  padding: 50px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  /* background-color: var(--p); */
  background-color: #2c2c2e;
  color: #fff;
  margin-bottom: 50px;
  text-align: center;
}
.footer-logo {
  width:400px;
  margin: 10px 0px;
}
.footer-container .social-media-links {
  display: flex;
  gap: 8px;
}
.social-media-links a {
  color: #fff;
}
.footer-container ul {
  padding: 0;
}
.footer-container ul li a {
  font-size: 16px;
  color: #fff;
  position: relative;
  cursor: pointer;
}
.footer-container ul li a:hover {
  color: #ebef29;
}
.social-links {
  padding: 10px;
}
.social-icons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: #333;
}

.mobile-sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  z-index: 99;
}
.mobile-sticky-footer ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.mobile-sticky-footer ul li {
  text-align: center;
  cursor: pointer;
}
.mobile-sticky-footer ul li a {
  color: #ebef29;
  font-size: 12px;
}
.mobile-sticky-footer li.active,
.mobile-sticky-footer li a.active {
  color: #26bd68;
}

.footer-container h6 {
  color: #fff;
  font-size: 18px;
}
.footer-container .icon {
  font-size: 35px;
}

.wa-icon-cont {
  position: fixed;
  right: 25px;
  bottom: 100px;
  background-color: #26bd68;
  padding: 8px;
  border-radius: 100px;
  z-index: 9999;
}
.wa-icon-cont a {
  color: #fff;
}
.wa-icon-cont .icon {
  font-size: 50px;
}
.cart-icon-cont {
  position: fixed;
  right: 25px;
  bottom: 180px;
  z-index: 9999;
  background-color: var(--s);
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}
.social-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .footer-container {
    padding: 20px;
    padding-bottom: 100px;
    text-align: center;
  }
  .footer-logo {
    width:350px;
  }
  .footer-para {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .copyright {
    font-size: 14px;
  }
}
