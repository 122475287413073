.view-order-summary {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
  
  .view-order-summary strong {
    color: #007bff;
  }
  
  /* Order Details Layout */
  .view-order-details {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 12px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
  }
  
  /* Labels */
  .order-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .order-item:last-child {
    border-bottom: none;
  }
  
  .label {
    font-weight: 600;
    color: #333;
  }
  
  .value {
    color: #555;
    text-align: right;
  }
  
  /* Mobile View */
  @media (max-width: 768px) {
    .view-order-details {
      grid-template-columns: 1fr;
    }
  
    .order-item {
      flex-direction: row;
      justify-content: space-between;
      padding: 12px;
    }
  
    .label {
      width: 40%;
    }
  
    .value {
      width: 60%;
      text-align: right;
    }
  }
  