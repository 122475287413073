.p-info-container {
  padding: 50px;
  padding-top: 180px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  align-items: flex-end;
  width: 100%;
}
.p-info-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.pro-img {
  z-index: 9999;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.pro-img img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
  margin-right: 15px;
}
.pro-img h2 {
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  z-index: 9999;
}
.pro-img h6 {
  color: #fff;
  z-index: 9999;
}
.pro-img .features {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.pro-img .features button {
  /* background-color: var(--t); */
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  color: #000;
  outline: transparent;
  margin-right: 10px;
  border: transparent;
  .icon {
    margin-right: 5px;
  }
}
.instruction-image {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
  /* background-color: var(--s); */
  background-color: #2c2c2e;
  padding-right: 50px;
  padding-top: 20px;
}
.instruction-image button {
  background-color: #2587d2;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  outline: transparent;
  border: transparent;
  font-size: 15px;
}
.ins-popup {
  display: none;
}
.ins-popup.active {
  position: relative;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  height: 400px;
  padding: 20px;
}
.ins-popup .close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  color: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  border-radius: 100px;
  font-size: 40px !important;
  cursor: pointer;
}

.ins-popup img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
}
.package-details {
  display: flex;
  /* background-color: var(--s); */
  background-color: #2c2c2e;
  flex-wrap: wrap;
}
.package-container {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}
.package-container .amount {
  width: 48%;
  border-radius: 10px;
  padding: 20px 0;
  color: #000;
  padding-left: 10px;
  /* background-color: var(--p); */
  background-color: #fff;
  margin-bottom: 20px;
  cursor: pointer;
}
.package-container .amount span {
  font-weight: 700;
}
.package-container .amount.active {
  /* border: 2px solid var(--t); */
  border: 2px solid #2587d2;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
}
.order-info {
  width: 40%;
  padding: 50px;
  color: #fff;
}
.pack-info {
  /* background-color: var(--p); */
  background-color: #2c2c2e;
  padding: 20px;
  border-radius: 10px;
  border: solid white;
}
.pack-info .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.player-tag {
  padding: 10px;
  width: 100%;
  outline: transparent;
  border-radius: 100px;
  background-color: #2c2c2e;
}
.p-check-btn {
  outline: transparent;
  border: transparent;
  border-radius: 100px;
  /* background-color: var(--t); */
  background-color: #fff;
  color: #333;
  font-weight: 800;
  padding: 10px 20px;
  width: 100%;
  margin-top: 10px;
}
.pro-desc-container {
  width: 60%;
  padding: 50px;
  color: #fff;
  padding-top: 0;
}
.pro-desc {
  /* background-color: var(--p); */
  background-color: #2c2c2e;
  padding: 20px;
  border-radius: 10px;
}
.pro-desc span {
  white-space: pre-wrap;
}
.related-games {
  color: #fff;
  padding: 50px;
  padding-top: 0;
}
.cart {
  display: flex;
  justify-content: space-between;
}
.cart-icon {
  position: relative;
}
.cart-icon span {
  position: absolute;
  top: -10px;
  right: -20px;
  background-color: var(--t);
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
.cart .icon {
  font-size: 30px;
}
.del-icon {
  color: var(--t);
  cursor: pointer;
}

/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
.payment {
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}
.payment .wallet.active {
  background-color: var(--t);
  color: #fff;
}
.payment .upi.active {
  background-color: var(--t);
  color: #fff;
}
.payment h4 {
  font-size: 20px;
}
.wallet,
.upi {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  h4 {
    font-weight: bold;
  }
}
.wallet {
  justify-content: space-between;
}
.wallet .icon {
  font-size: 30px;
}
.upi img {
  width: 70px;
  border-radius: 10px;
}
.coinimg {
  width: 35px;
}

/* ORDER SUCCESS CONTAINER */
.order-success-container {
  min-height: 100vh;
  background: linear-gradient(to bottom, var(--p), var(--s));
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-success-container .order-container {
  width: 40%;
  .order-item {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px;
  }
}

/* LAODING CONTAINER  */
.loading-container {
  background-color: var(--s);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #fff;
  h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .p-info-container {
    padding: 10px;
    padding-top: 100px;
  }
  .pro-img h2 {
    font-size: 15px;
    margin: 0;
  }
  .pro-img img {
    width: 80px;
    height: 80px;
  }
  .pro-img .features button {
    font-size: 8px;
    padding: 2px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 5px;
    .icon {
      font-size: 10px;
    }
  }
  .ins-popup img {
    width: 100%;
    height: 100%;
  }
  /* package  */
  .package-container,
  .order-info,
  .pro-desc-container {
    width: 100%;
    padding-bottom: 0;
  }
  .pro-desc-container {
    margin-bottom: 50px;
  }
  .instruction-image {
    padding-right: 20px;
  }
  .instruction-image button {
    font-size: 10px;
  }
  .instruction-image .icon {
    font-size: 15px;
  }
}

@media screen and (max-width: 786px) {
  /* package  */
  .package-container,
  .order-info,
  .pro-desc-container {
    width: 100%;
    padding: 50px 20px;
    padding-bottom: 0;
    padding-top: 20px;
  }
  .order-success-container {
    min-height: 60vh;
  }
  .order-success-container .order-container {
    width: 100%;
  }
  /* LAODING CONTAINER  */
  .loading-container {
    min-height: 70vh;
    h4 {
      font-size: 15px;
    }
  }
}

/* ================================================================================ */

.product-info-container {
  padding: 80px 150px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  background-color: var(--s);
  color: #fff;
}
.product-info-img-slider {
  width: 7%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product-info-img-slider img {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  object-fit: cover;
}
.product-info-img {
  width: 30%;
  height: 350px;
}
.product-info-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-info-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}
.product-info-content select {
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 50%;
  color: #fff;
}
.player-tag {
  padding: 10px;
  width: 100%;
  outline: transparent;
  border: 1px solid grey;
  margin-top: 10px;
}
.product-info-content h3 {
  text-transform: uppercase;
}
.add-to-cart-btn {
  width: 50%;
  /* background-color: var(--s); */
  background-color: #fff;
  color: #000;
  padding: 10px;
  outline: transparent;
  border: transparent;
  margin-top: 10px;
  border: 1px solid white;
  &:hover {
    opacity: 0.8;
  }
}
.safe-checkout-img {
  width: 35%;
  margin-top: 10px;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
.tab-btns-desc {
  display: flex;
}
.tab-btns-desc .tab {
  padding: 10px;
  text-align: center;
  width: 50%;
  background-color: #eee;
  cursor: pointer;
}
.tab-btns-desc .tab.active {
  background-color: #ebef29;
}
.product-info-details {
  margin-top: 20px;
  min-height: 250px;
}

.p-amount {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.amount img {
  width: 30px;
  margin-right: 10px;
}
@media screen and (max-width: 992px) {
  .product-info-container {
    padding: 50px 20px;
  }
  .product-info-img-slider {
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
  .product-info-img-slider img {
    width: 20%;
    margin-right: 10px;
  }
  .product-info-img {
    width: 100%;
  }
  .product-info-content {
    width: 100%;
    padding-left: 0;
  }
  .select-brand select {
    width: 100%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
  .safe-checkout-img {
    width: 100%;
  }
  .product-info-content select,
  .player-tag {
    width: 100%;
  }
}

/* ======================= EFFECT */
.area {
  position: relative;
}
.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  animation: animate 25s linear infinite;
  bottom: -150px;
  border-radius: 0px !important;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
/* ========================================================= BG EFFECT  */

.spinner {
  --size: 50px;
  --first-block-clr: #fff;
  --second-block-clr: #fed500;
  --clr: #111;
  width: 100px;
  height: 100px;
  position: relative;
}

.spinner::after,
.spinner::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  width: var(--size);
  height: var(--size);
  top: 50%;
  animation: up 2s cubic-bezier(0, 0, 0.24, 1.21) infinite;
  left: 50%;
  background: var(--first-block-clr);
  border-radius: 100px;
}

.spinner::after {
  background: var(--second-block-clr);
  top: calc(50% - var(--size));
  left: calc(50% - var(--size));
  animation: down 2s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes down {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

@keyframes up {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}


.info-btn {
  background-color: #2587d2;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

.order-information-title{
  display: flex;

}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 300px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
}

.popup-content img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
