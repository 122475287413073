.sticky-footer {
  display: none;
}

@media screen and (max-width: 600px) {
  .sticky-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: block;
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
  }
  .sticky-footer a {
    color: #000;
  }
  .sticky-footer .footer-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
