/* Container for the entire slider */
.hero-slider-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
}

/* Individual banner slides */
.banner-slide {
  margin-top: 20px;
  position: relative;
  width: 95%;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-left: auto;  /* Centering horizontally */
  margin-right: auto;
  max-height: 400px;
}

/* Image behavior for both desktop and mobile */
.banner-slide img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: fill;  /* Ensures the image covers the entire container without stretching */
}

/* Hover effect */
.banner-slide:hover {
  transform: scale(1.02);
}

/* Overlay content for the banner */
.banner-content {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 8px;
  max-width: 70%;
}

.banner-content h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.banner-content p {
  font-size: 1rem;
  margin: 0;
}

/* Slick dots styling */
.slick-dots {
  bottom: 0;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: #f39c12;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .hero-slider-container {
    max-width: 100%;
    margin: 0;
  }

  .banner-slide {
    height: 250px; /* Adjust height for smaller screens */
  }

  .banner-content h2 {
    font-size: 1.4rem; /* Adjust title font size */
  }

  .banner-content p {
    font-size: 0.9rem; /* Adjust description font size */
  }
}

@media (max-width: 480px) {
  .banner-slide {
    height: 200px; /* Further adjust height for mobile */
  }
  .banner-slide img {
    height: 90%;
  }

  .banner-content h2 {
    font-size: 1.2rem; /* Smaller title font size */
  }

  .banner-content p {
    font-size: 0.8rem; /* Smaller description font size */
  }
}
