.admin-users-container {
    padding: 20px;
  }
  
  .page-title h3 {
    margin: 0;
    color: black;
  }
  
  .total-users-count {
    margin-bottom: 20px;
  }
  
  .tools {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .per-page-selector {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .table-container {
    display: none; /* Hide table view by default */
  }
  
  .user-card-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .user-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    color: black;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .user-card div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
  }
  
  .user-card div:last-child {
    border-bottom: none;
  }
  
  .user-card strong {
    color: black;
    font-weight: bold;
  }
  
  .action {
    display: flex;
    justify-content: flex-end;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
  }
  
  .btn-pagination {
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn-pagination.active {
    background-color: #007bff;
    color: white;
  }
  
  .dots {
    padding: 5px 10px;
  }
  
  @media (min-width: 768px) {
    .table-container {
      display: block; /* Show table view on larger screens */
    }
  
    .user-card-container {
      display: none; /* Hide card view on larger screens */
    }
  }